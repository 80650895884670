<template>
    <div class="card card-widget">
        <!-- Add the bg color to the header using any of the bg-* classes -->
        <div class="card-footer p-0">
            <ul class="nav flex-column">
            <li class="nav-item">
                <a href="#" class="nav-link">
                {{ $t('general.superadministrador') }}<span class="float-right badge bg-primary">2</span>
                </a>
            </li>
            <li class="nav-item">
                <a href="#" class="nav-link">
                {{ $t('general.administrador') }} <span class="float-right badge bg-info">5</span>
                </a>
            </li>
            <li class="nav-item">
                <a href="#" class="nav-link">
                {{ $t('general.gestion') }} <span class="float-right badge bg-success">12</span>
                </a>
            </li>
            <li class="nav-item">
                <a href="#" class="nav-link">
                {{ $t('general.companias') }} <span class="float-right badge bg-danger">8</span>
                </a>
            </li>
            </ul>
        </div>
    </div>
</template>